import React from "react";
import { Spinner } from "@chakra-ui/react";
import LogoIcon from "@gigauser/common/src/assets/logos/LogoIcon.svg";
import './LogoLoader.css';

type LogoLoaderProps = {};

const LogoLoader: React.FC<LogoLoaderProps> = () => {
	return (
		<div className="LogoLoader">
			<img src={LogoIcon} className="LoadingIcon"></img>
			<Spinner color="#d43f8c" size={"l"} />
		</div>
	);
};
export default LogoLoader;
